import "./styles.scss"

import React from "react"

const Banner = ({ children, image }) => {
  return (
    <>
      {children ? (
        <section className="csr-banner">
          <div className="container-fluid container-fluid--csr">{children}</div>
        </section>
      ) : null}
      {image ? (
        <section className="csr-image">
          <img src={image} alt="" />
        </section>
      ) : null}
    </>
  )
}

export default Banner
