import "./styles.scss"

import React from "react"

const Heading = ({ title, description }) => {
  return (
    <div className="csr-heading">
      <div className="row">
        <div className="col-lg-6">
          <h3>{title}</h3>
        </div>
        <div className="col-lg-6">
          <div className="csr-heading__description">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Heading
