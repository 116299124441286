import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  Hero,
  Gallery,
  Movie,
  Content,
  Banner,
  Section,
  Heading,
  TimeItem,
} from "../page_components/krakow-sky-run"

const KrakowSkyRun = () => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/krakow-sky-run/">
      <SEO title="Krakow Sky Run" />

      <Hero
        description="Stay ahead of the competition and lead the way in the SPEED RUN
              with UNITY CENTRE, Partner of ABSL Summit 2024."
      />
      <Content
        roundTitle={
          <>
            SPEED RUN
            <br />
            will be held in two rounds
          </>
        }
        roundDescription={{
          first:
            "For registered ABSL Summit participants AND employees of companies whose representatives registered for ABSL Summit.",
          second:
            "For employees of Kraków-based business services sector organizations. All companies invited!",
        }}
        lang={lang}
      >
        <h2>
          Kraków Sky Run –<br />
          Powered by UNITY Centre
        </h2>
        <p>
          Get ahead of the competition and take part in a unique race at UNITY
          Tower, located at 20 Aleksander Lubomirski Street in Krakow. Run to
          the 25th floor, give it your all to achieve the best time, and win
          prizes!
        </p>
      </Content>
      <Banner>
        <h4>
          We have already completed two editions of the
          <br />
          Kraków Sky Run 2024 - Powered by Unity Centre,
          <br />
          which were incredible events.
        </h4>
        <h3>The next edition will take place in 2025.</h3>
      </Banner>
      <Gallery />
      <Movie />
      <Section
        heading={
          <Heading
            title="Kraków Sky Run 2023"
            description="Over 90 people participated in the event, aiming to complete the run to the 25th floor of the skyscraper. Participants were greeted with gifts and prizes, as well as a relaxation area with an incredible view of the city skyline."
          />
        }
        content={[
          {
            headings: null,
            elements: [
              <img
                src={require("../assets/images/krakow-sky-run/time-icon.svg")}
                alt=""
              />,
            ],
            isHiddenMobile: true,
          },
          {
            headings: ["THE BEST TIMES"],
            elements: [
              <TimeItem time="3:14" title="of woman" />,
              <TimeItem time="2:26" title="of man" />,
            ],
          },
        ]}
        isContentBottomAligned
        isGray
      />
      <Section
        heading={
          <Heading
            title="Kraków Sky Run 2024"
            description="A total of 120 people registered for the second edition. The event garnered significant interest. Each participant received a gift and a commemorative medal. We invite you to watch the event highlights."
          />
        }
        content={[
          {
            headings: ["THE BEST TIMES", "WOMANS"],
            elements: [
              <TimeItem
                time="3:17"
                title="1st place"
                labels={["MONIKA PRZYBYŁO"]}
              />,
              <TimeItem
                time="3:18"
                title="2nd place"
                labels={["MARGARYTA IEVTUKH"]}
              />,
              <TimeItem
                time="3:29"
                title="3rd place"
                labels={["JULIANNA SIWEK", "ANETA SAMKOFF"]}
              />,
            ],
          },
          {
            headings: ["THE BEST TIMES", "MANS"],
            elements: [
              <TimeItem
                time="2:29"
                title="1st place"
                labels={["PAWEŁ BARCIK"]}
              />,
              <TimeItem
                time="2:31"
                title="2nd place"
                labels={["ANDRZEJ OCHOWICZ"]}
              />,
              <TimeItem
                time="2:52"
                title="3rd place"
                labels={["TYMOTEUSZ KRUK"]}
              />,
            ],
          },
        ]}
      />
      <Banner
        image={require("../assets/images/krakow-sky-run/medals-image.jpg")}
      />
      <Banner
        image={require("../assets/images/krakow-sky-run/group-image.jpg")}
      >
        <h4>
          You are warmly invited to
          <br />
          the third edition of Kraków Sky Run 2025 - Powered by Unity Centre!
        </h4>
        <p>
          Join us for this incredible event and experience unforgettable
          moments.
          <br />
          More information coming soon.
        </p>
        <h3>See you at the starting line!</h3>
      </Banner>
    </Layout>
  )
}

export default KrakowSkyRun
