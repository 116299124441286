import "./styles.scss"

import React from "react"

import t from "../../../locale"

const Content = ({ children, roundTitle, roundDescription, lang }) => {
  return (
    <section className="csr-content">
      <div className="container-fluid container-fluid--csr">
        {children && <div className="csr-content__description">{children}</div>}

        <div className="csr-content__description csr-content__description--full">
          {roundTitle && <h2>{roundTitle}</h2>}
          <div className="csr-content__rounds">
            {roundDescription?.first && (
              <div>
                <h3>{t("Round 1", lang)}:</h3>
                <p>{roundDescription.first}</p>
              </div>
            )}
            {roundDescription?.second && (
              <div>
                <h3>{t("Round 2", lang)}:</h3>
                <p>{roundDescription.second}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Content
