import "./styles.scss"

import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import LazyLoad from "react-lazy-load"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allFile(filter: { relativePath: { glob: "krakow-sky-run/gallery/*" } }) {
        nodes {
          childImageSharp {
            original {
              src
            }
          }
          name
        }
      }
    }
  `)
  
  const slider = {
    className: "csr-gallery__slider",
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const sortedData = data?.allFile?.nodes?.sort((a, b) => {
    const numA = parseInt(a.name.split("-")[1])
    const numB = parseInt(b.name.split("-")[1])
    return numA - numB
  })

  return (
    <section className="csr-gallery">
      {sortedData?.length > 0 && (
        <Slider {...slider}>
          {data?.allFile?.nodes?.map((item, index) => (
            <div className="csr-gallery__slider-item" key={index}>
              <LazyLoad>
                <img src={item.childImageSharp?.original.src} alt="" />
              </LazyLoad>
            </div>
          ))}
        </Slider>
      )}
    </section>
  )
}

export default Gallery
