import "./styles.scss"

import React from "react"

const Section = ({ heading, content, isGray, isContentBottomAligned }) => {
  return (
    <section className={`csr-section${isGray ? " csr-section--gray" : ""}`}>
      <div className="container-fluid container-fluid--csr">
        {heading}
        {content?.length > 0 && (
          <div
            className={`row row--inner${
              isContentBottomAligned ? " row--align-bottom" : ""
            }`}
          >
            {content.map((item, index) => (
              <div
                className={`col-xl-${content.length > 1 ? "6" : "12"}${item?.isHiddenMobile ? ' d-none d-xl-block' : ''}`}
                key={index}
              >
                {item?.headings?.length > 0 && (
                  <div className="csr-section__headings">
                    {item.headings.map((heading, index) => (
                      <p key={index}>{heading}</p>
                    ))}
                  </div>
                )}
                {item?.elements?.length > 0 && (
                  <div className="csr-section__elements">
                    {item.elements.map((element, index) => (
                      <div key={index}>{element}</div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default Section
