import "./styles.scss"

import React from "react"

const TimeItem = ({ time, title, labels }) => {
  return (
    time && (
      <div className="csr-time-item">
        <div>
          <h4>
            <span>{time}</span>
          </h4>
          {title && <h5>{title}</h5>}
          {labels?.length > 0 && (
            <div className="csr-time-item__labels">
              {labels.map((label, index) => (
                <p key={index}>{label}</p>
              ))}
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default TimeItem
