import "./styles.scss"

import React from "react"

const Hero = ({ description }) => {
  return (
    <section className="csr-hero">
      <div className="container-fluid container-fluid--csr">
        <div className="row">
          <div>
            <h1>Ready, steady... GO!</h1>
          </div>
          <div>
            <p>{description}</p>
          </div>
        </div>
      </div>

      <div className="csr-hero__image" />
    </section>
  )
}

export default Hero
