import "./styles.scss"

import React from "react"

const Movie = () => {
  return (
    <section className="csr-movie">
      <div className="container-fluid container-fluid--csr">
        <div className="csr-movie__video">
          <video width="1364" height="720" controls>
            <source
              src={require("../../../assets/videos/krakow-sky-run.mp4")}
              type="video/mp4"
            />
            <track kind="captions" />
          </video>
        </div>
      </div>
    </section>
  )
}

export default Movie
